module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N28BS6G","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            \"restaurant\": {\n              \"name\": document.cookie.match(/;?location=(\\w+);?/) ? document.cookie.match(/;?location=(\\w+);?/)[1] : 'not set',\n              \"id\": \"<id of resturant>\"\n            }\n          }\n        }"},"gtmAuth":"Gfa4AADWBu1Op3uJBX3VCQ","gtmPreview":"env-2","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Sporting Globe","short_name":"Sporting Globe","background_color":"#292929","theme_color":"#000","display":"standalone","icon":"src/images/logo.png","prefer_related_applications":true,"related_applications":[{"platform":"play","id":"com.sportingglobe.sportingglobe"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"73d943c05a0bfcef3bb151df645d6fba"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
