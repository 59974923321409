import React, { useRef } from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { selectLocation } from "../state/store"
import { Socials, AppStores } from './Socials'
import { useSelector } from "react-redux"
import './scss/MenuContent.scss'

/**
 * The side menu on mobiles content
 * 
 * @param {function} onClickFunction 
 * @returns a menu view when opened
 */
export const MenuContent = ({ onClickFunction }) => {
  const menu = useRef()
  const mainOptions = ['Order Now', 'Menu', 'Make a Booking', "What’s On", 'Tipping', 'Functions', 'Locations', 'Members', 'Feedback']
  const secondaryOptions = ['Gift Cards', 'Partners', 'Sponsorship', 'Careers', 'Franchising']
  const myLocal = useSelector(selectLocation)

  /**
   * Turns the nav option name into the relevent url to be used in the route
   * 
   * @param {string} option the selected menu option
   * @returns a url string
   */
  const createLink = (option) => {
    const url = ((option.toLocaleLowerCase()).replace(' ', '')).replace(/’/g, '')
    if (url === 'menu' || url === 'whatson' || url === 'functions') {
      return `/${url}${myLocal === '' ? '' : '/' + myLocal}`
    } else if (url === 'ordernow') {
      return '/order/'
    } else if (url === 'giftcards') {
      return '/gift-cards/'
    } else if (url === 'franchising') {
      return `/franchise/`
    } else if (url === 'makea booking') {
      return '/book/'
    } else {
      return `/${url}/`
    }
  }

  return (
    <div className="menu" ref={menu}>
      <div className='menu__topbar' ></div>
      <ul className="menu__links menu__top">
        {mainOptions.map((option) =>
          <li key={option}>
            <Link onClick={() => { onClickFunction(menu) }} to={createLink(option)}>{option}</Link>
          </li>
        )}
      </ul>
      <ul className="menu__links menu__small">
        {secondaryOptions.map((option) =>
          <li key={option}>
            <Link onClick={() => { onClickFunction(menu) }} to={createLink(option)}>{option}</Link>
          </li>
        )}
      </ul>
      <div className="menu__socials">
        <Socials />
      </div>
      <div className="menu__appstores">
        <AppStores />
      </div>
    </div >

  )
}

MenuContent.propTypes = {
  onClickFunction: PropTypes.func.isRequired,
}
